import { Component } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { VersionComponent } from "../../utils/version/version.component";

@Component({
  selector: "app-footer",
  standalone: true,
  templateUrl: "./footer.component.html",
  styles: ``,
  imports: [TranslateModule, VersionComponent],
})
export class FooterComponent {}
