import {
  AfterViewInit,
  Component,
  ElementRef,
  ViewChild,
  computed,
  effect,
  input,
} from "@angular/core";
import { toObservable } from "@angular/core/rxjs-interop";
import { NgIconComponent, provideIcons } from "@ng-icons/core";
import { lucideCheckCheck } from "@ng-icons/lucide";
import {
  concatMap,
  delay,
  from,
  of,
  pairwise,
  startWith,
  switchMap,
} from "rxjs";

@Component({
  selector: "app-progress-indicator",
  standalone: true,
  imports: [NgIconComponent],
  templateUrl: "./progress-indicator.component.html",
  styles: ``,
  viewProviders: [provideIcons({ lucideCheckCheck })],
})
export class ProgressIndicatorComponent implements AfterViewInit {
  @ViewChild("progress")
  progress?: ElementRef<HTMLDivElement>;

  value = input.required<number>();

  value$ = toObservable(this.value);

  max = input.required<number>();

  size = input<"sm" | "md" | "lg">("md");

  variant = input<"percentage" | "xOfY">("percentage");

  iconSize = computed(() => {
    if (this.size() == "sm") {
      return "10px";
    } else if (this.size() == "md") {
      return "16px";
    } else {
      return "32px";
    }
  });

  // values less than 100ms are ignored
  fullAnimationDurationMs = input<number>(1000);

  private animationStepDelayMs = computed(
    () => Math.max(1, Math.round(this.fullAnimationDurationMs() / 100 /* % */)), // full 100%
  );

  percentage = computed(() =>
    this.calculatePercentage(this.value(), this.max()),
  );

  private calculatePercentage(value: number, max: number) {
    const percentage =
      max > 0 ? Math.max(0, Math.min(100, Math.round(100 * (value / max)))) : 0;
    return percentage;
  }

  constructor() {
    effect(() => {
      if (this.progress) {
        const size = this.size();
        if (size == "sm") {
          this.progress.nativeElement.style.setProperty("--size", "3rem");
          this.progress.nativeElement.style.setProperty("font-size", "0.7rem");
        } else if (size == "md") {
          this.progress.nativeElement.style.setProperty("--size", "5rem");
          this.progress.nativeElement.style.setProperty("font-size", "1rem");
        } else {
          this.progress.nativeElement.style.setProperty("--size", "12rem");
          this.progress.nativeElement.style.setProperty("font-size", "1.5rem");
        }
      }
    });

    this.value$
      .pipe(
        startWith(0),
        pairwise(),
        switchMap(([previous, current]) => {
          const fromPercentage = this.calculatePercentage(previous, this.max());
          const toPercentage = this.calculatePercentage(current, this.max());

          // console.log(`from: ${fromPercentage} to ${toPercentage}`);

          return from(fromToGenerator(fromPercentage, toPercentage)).pipe(
            concatMap((x) => of(x).pipe(delay(this.animationStepDelayMs()))),
          );
        }),
      )
      .subscribe((value) => {
        // console.log(`value=${value}`);
        this.setProgress(value);
      });
  }

  ngAfterViewInit(): void {
    this.setProgress(this.percentage());
  }

  private setProgress(value: number) {
    if (this.progress) {
      // console.log(`value=${value}`);
      this.progress.nativeElement.style.setProperty("--value", "" + value);
    }
  }
}

function* fromToGenerator(from: number, to: number) {
  // console.log(`fromToGenerator: ${from} -> ${to}`);
  if (from < to) {
    for (let i = from; i <= to; ++i) {
      yield i;
    }
  } else {
    for (let i = from; i >= to; --i) {
      yield i;
    }
  }
}
