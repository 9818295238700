"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FrappeRequestListOptions = exports.FrappeRequestFilter = void 0;
class FrappeRequestFilter {
    frappeField;
    operator;
    filterValue;
    type = "AND";
    constructor(frappeField, operator, filterValue) {
        this.frappeField = frappeField;
        this.operator = operator;
        this.filterValue = filterValue;
    }
    filterType() {
        return this.type;
    }
    filter() {
        return [this.frappeField, this.operator, this.filterValue];
    }
    asOr() {
        this.type = "OR";
        return this;
    }
    static byName(name) {
        return new FrappeRequestFilter("name", "=", name);
    }
    static submitted() {
        return new FrappeRequestFilter("docstatus", "=", "1");
    }
    static byOwner(owner) {
        return new FrappeRequestFilter("owner", "=", owner);
    }
}
exports.FrappeRequestFilter = FrappeRequestFilter;
class FrappeRequestListOptions {
    filters = [];
    pageLength;
    skip;
    orderBy;
    extraParams = [];
    withFilters(filters) {
        filters.forEach((filter) => this.withFilter(filter));
        return this;
    }
    withOrderBy(field, direction) {
        this.orderBy = { field, direction };
        return this;
    }
    withFilter(filter) {
        this.filters = [...this.filters, filter];
        return this;
    }
    withPageLength(length) {
        if (length > 0) {
            this.pageLength = length;
        }
        return this;
    }
    withSkip(skip) {
        if (skip > 0) {
            this.skip = skip;
        }
        return this;
    }
    withParam(key, value) {
        this.extraParams = [...this.extraParams, { key, value }];
        return this;
    }
}
exports.FrappeRequestListOptions = FrappeRequestListOptions;
