import { Component } from "@angular/core";
import { LogoutComponent } from "../../authentication/logout/logout.component";
import { ThemeSwitcherComponent } from "../../utils/theme-switcher/theme-switcher.component";
import { TranslateModule } from "@ngx-translate/core";
import { Store } from "@ngrx/store";
import { AppState } from "src/app/ngrx/reducers";
import { AuthenticationSelectors } from "src/app/ngrx/selectors";
import { UserInfo } from "src/app/models/authentication/user-info";
import { RouterModule } from "@angular/router";

@Component({
  selector: "app-menu",
  standalone: true,
  templateUrl: "./menu.component.html",
  styles: ``,
  imports: [
    LogoutComponent,
    ThemeSwitcherComponent,
    TranslateModule,
    RouterModule,
  ],
})
export class MenuComponent {
  userInitials: string = "";

  private createInitials(user: UserInfo): string {
    var firstNameStart = "";
    var lastNameStart = "";
    if (user.firstName.length > 0) {
      firstNameStart = user.firstName[0];
    }
    if (user.lastName.length > 0) {
      lastNameStart = user.lastName[0];
    }
    return firstNameStart + lastNameStart;
  }

  constructor(private readonly store: Store<AppState>) {
    this.store
      .select(AuthenticationSelectors.authentication)
      .subscribe((auth) => {
        if (auth.user) {
          this.userInitials = this.createInitials(auth.user);
        }
      });
  }
}
