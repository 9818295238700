import { Injectable } from "@angular/core";
import { Group } from "../models/group/group";
import { Observable } from "rxjs";
import { GroupUser } from "../models/group/group-user";
import {
  mappingBuilderWithDefaultMappings,
  mappingHelper,
  FrappeApiHelper,
} from "@aht/frappe-client";

@Injectable({
  providedIn: "root",
})
export class GroupService {
  private groupMapping = mappingBuilderWithDefaultMappings<Group>("SAR Group", [
    mappingHelper.stringMapper("title"),
    mappingHelper.stringMapper("description"),
  ]);

  private groupUserMapping = mappingBuilderWithDefaultMappings<GroupUser>(
    "SAR Group User",
    [mappingHelper.stringMapper("user"), mappingHelper.stringMapper("group")],
  );

  constructor(private readonly helper: FrappeApiHelper) {}

  loadMyGroups(): Observable<Group[]> {
    return this.helper.callWithResults(this.groupMapping.conversion(), {
      method: "sar.controllers.group_controller.my_groups",
      type: "GET",
    });
  }

  loadMyGroupDetails(group: string): Observable<Group> {
    const extraParams = new Map<string, string>();
    extraParams.set("group", group);

    return this.helper.callWithResult(this.groupMapping.conversion(), {
      method: "sar.controllers.group_controller.my_group",
      type: "GET",
      extraParams: extraParams,
    });
  }
}
