"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConversionExtension = void 0;
const rxjs_1 = require("rxjs");
class ConversionExtension {
    conversion;
    constructor(conversion) {
        this.conversion = conversion;
    }
    convertOptional(item) {
        if (item) {
            return this.conversion.convert(item);
        }
        else {
            return;
        }
    }
    convertSingle(item) {
        return this.conversion.convert(item);
    }
    convertMany(items) {
        return (items || []).map((item) => this.conversion.convert(item));
    }
    convertObservableMany(obs) {
        return obs.pipe((0, rxjs_1.map)((elements) => this.convertMany(elements)));
    }
    convertObservableSingle(obs) {
        return obs.pipe((0, rxjs_1.map)((element) => this.convertSingle(element)));
    }
    convertObservableOptionalSingle(obs) {
        return obs.pipe((0, rxjs_1.map)((element) => this.convertOptional(element)));
    }
}
exports.ConversionExtension = ConversionExtension;
