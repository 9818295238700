import { Inject, Injectable } from "@angular/core";
import { Instruction } from "../models/instruction/instruction";

import { environment } from "src/environments/environment";
import { HttpParams } from "@angular/common/http";
import { UrlSanitizer } from "@aht/frappe-client";

@Injectable({
  providedIn: "root",
})
export class PDFFileService {
  downloadUrlForInstruction(instruction: Instruction): string {
    const url = new UrlSanitizer()
      .addUrlPart(environment.apiUrl)
      .addUrlPart(
        "/api/method/sar.controllers.instruction_controller.download_file",
      );

    const params = new HttpParams().append("file_url", instruction.file);
    return url.url() + "?" + params.toString();
  }
}
