<app-loading-indicator
  [isLoading]="isLoadingMyInstruction || isLoadingMyInstructionConfirmation"
></app-loading-indicator>

@if (myInstruction) {
  <div
    class="flex flex-grow flex-col"
    appResize
    (resized)="handleResized($event)"
  >
    <p class="text-2xl md:text-3xl">{{ myInstruction.title }}</p>
    <div class="flex flex-col text-sm md:flex-row md:text-base">
      @if (myInstructionConfirmation) {
        <div class="me-0 text-primary md:me-4">
          <span>{{
            "instruction.instruction-detail.confirmed" | translate
          }}</span>
          <span>
            {{
              myInstructionConfirmation.modified
                | date: ("common.dateTimeFormat" | translate)
            }}
          </span>
        </div>
      }
      <div class="text-neutral-600">
        {{ "instruction.instruction-detail.created" | translate }}
        {{
          myInstruction.modified | date: ("common.dateTimeFormat" | translate)
        }}
      </div>
    </div>

    <ng-template #confirmButton>
      <button
        class="btn btn-primary"
        (click)="confirmationDialog.showModal()"
        [disabled]="
          myInstructionConfirmation != undefined || pageNumber != numPages
        "
      >
        @if (isConfirmingMyInstruction) {
          <span class="loading loading-spinner"></span>
        }
        {{ "instruction.instruction-detail.confirm" | translate }}
      </button>
    </ng-template>

    @if (numPages > 1) {
      <div class="flex flex-row justify-between">
        <button
          (click)="pageNumber = pageNumber - 1"
          [disabled]="pageNumber <= 1"
          class="btn btn-primary"
        >
          <
        </button>

        @if (numPages == pageNumber && !myInstructionConfirmation) {
          <ng-container *ngTemplateOutlet="confirmButton"></ng-container>
        } @else {
          <p class="text-lg">{{ pageNumber }} / {{ numPages }}</p>
        }

        <button
          (click)="pageNumber = pageNumber + 1"
          [disabled]="pageNumber == numPages"
          class="btn btn-primary"
        >
          >
        </button>
      </div>
    } @else if (!myInstructionConfirmation) {
      <div class="flex flex-col items-center">
        <ng-container *ngTemplateOutlet="confirmButton"></ng-container>
      </div>
    }
    <div class="flex flex-col items-center">
      <app-loading-indicator
        [isLoading]="!hasRenderedFirstPage"
      ></app-loading-indicator>
      <pdf-viewer
        [srcUrl]="pdfUrl"
        (pdfLoaded)="pdfLoaded($event)"
        [pageNumber]="pageNumber"
        [width]="!hasRenderedFirstPage ? 1 : pdfWidth"
        [height]="!hasRenderedFirstPage ? 1 : pdfHeight"
        (firstPageRendered)="handleFirstPageRendered()"
      ></pdf-viewer>
    </div>
  </div>
}

<dialog
  class="modal"
  #confirmationDialog
  (close)="dialogClosed(confirmationDialog)"
>
  <div class="modal-box">
    <h3 class="text-lg font-bold">
      {{
        "instruction.instruction-detail.confirmation-dialog.title" | translate
      }}
    </h3>
    <p class="py-4">
      {{
        "instruction.instruction-detail.confirmation-dialog.content" | translate
      }}
    </p>
    <div class="modal-action">
      <button class="btn" (click)="close(confirmationDialog, 'cancel')">
        {{ "common.cancel" | translate }}
      </button>
      <button
        class="btn btn-primary"
        (click)="close(confirmationDialog, 'confirm')"
      >
        {{ "common.confirm" | translate }}
      </button>
    </div>
  </div>
</dialog>
