import { Component } from "@angular/core";
import { InstructionDetailComponent } from "../instruction-detail/instruction-detail.component";
import { ActivatedRoute } from "@angular/router";
import { Store } from "@ngrx/store";
import { InstructionActions } from "src/app/ngrx/actions";
import { AppState } from "src/app/ngrx/reducers";
import { Instruction } from "src/app/models/instruction/instruction";

@Component({
  selector: "app-instruction-detail-page",
  standalone: true,
  templateUrl: "./instruction-detail-page.component.html",
  styles: ``,
  imports: [InstructionDetailComponent],
})
export class InstructionDetailPageComponent {
  instruction?: Instruction;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly store: Store<AppState>,
  ) {}

  ngOnInit(): void {
    const instructionName =
      this.activatedRoute.snapshot.paramMap.get("name") || "";

    this.store.dispatch(
      InstructionActions.loadMyInstruction({ name: instructionName }),
    );
  }
}
