import { createReducer, on } from "@ngrx/store";
import { InstructionActions } from "../actions";
import { Instruction } from "src/app/models/instruction/instruction";
import { InstructionConfirmation } from "src/app/models/instruction/instruction-confirmation";

export interface InstructionState {
  myInstructions: Instruction[];
  isLoadingMyInstructions: boolean;
  myConfirmedInstructions: Instruction[];
  isLoadingMyConfirmedInstructions: boolean;
  myInstruction?: Instruction;
  isLoadingMyInstruction: boolean;
  myInstructionConfirmation?: InstructionConfirmation;
  isLoadingMyInstructionConfirmation: boolean;
  isConfirmingMyInstruction: boolean;
}

const initialState = {
  isLoadingMyInstructions: false,
  myInstructions: [],
  myConfirmedInstructions: [],
  isLoadingMyConfirmedInstructions: false,
  myInstruction: undefined,
  isLoadingMyInstruction: false,
  isLoadingMyInstructionConfirmation: false,
  isConfirmingMyInstruction: false,
} as InstructionState;

export const instructionReducer = createReducer(
  initialState,
  on(InstructionActions.loadMyInstructions, () => ({
    ...initialState,
    myInstructions: [],
    isLoadingMyInstructions: true,
  })),
  on(InstructionActions.loadedMyInstructions, (state, action) => ({
    ...state,
    isLoadingMyInstructions: false,
    myInstructions: action.instructions,
  })),
  on(InstructionActions.loadMyConfirmedInstructions, (state, _) => ({
    ...state,
    isLoadingMyConfirmedInstructions: true,
    myConfirmedInstructions: [],
  })),
  on(InstructionActions.loadedMyConfirmedInstructions, (state, action) => ({
    ...state,
    isLoadingMyConfirmedInstructions: false,
    myConfirmedInstructions: action.confirmedInstructions,
  })),
  on(InstructionActions.loadMyInstruction, (state, _) => ({
    ...state,
    isLoadingMyInstruction: true,
    myInstruction: undefined,
  })),
  on(InstructionActions.loadedMyInstruction, (state, action) => ({
    ...state,
    isLoadingMyInstruction: false,
    myInstruction: action.instruction,
  })),
  on(InstructionActions.loadMyInstructionConfirmation, (state, action) => ({
    ...state,
    isLoadingMyInstructionConfirmation: true,
    myInstructionConfirmation: undefined,
  })),
  on(InstructionActions.loadedMyInstructionConfirmation, (state, action) => ({
    ...state,
    isLoadingMyInstructionConfirmation: false,
    myInstructionConfirmation: action.confirmation,
  })),
  on(InstructionActions.confirmMyInstruction, (state) => ({
    ...state,
    isConfirmingMyInstruction: true,
    myInstructionConfirmation: undefined,
  })),
  on(InstructionActions.confirmedMyInstruction, (state, action) => ({
    ...state,
    isConfirmingMyInstruction: false,
    myInstructionConfirmation: action.confirmation,
  })),
  on(InstructionActions.confirmMyInstructionFailed, (state, _) => ({
    ...state,
    isConfirmingMyInstruction: false,
    myInstructionConfirmation: undefined,
  })),
);
