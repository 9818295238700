import { createSelector } from "@ngrx/store";
import { AppState } from "../reducers";

const groupFeature = (state: AppState) => state.group;

export const myGroups = createSelector(groupFeature, (state) => ({
  myGroups: state.myGroups,
  isLoadingGroups: state.isLoadingGroups,
}));

export const myGroupDetails = createSelector(groupFeature, (state) => ({
  isLoadingMyGroupDetails: state.isLoadingMyGroupDetails,
  myGroupDetails: state.myGroupDetails
}));
