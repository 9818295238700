import { Component, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { Group } from "src/app/models/group/group";
import { AppState } from "src/app/ngrx/reducers";
import { GroupSelectors } from "src/app/ngrx/selectors";
import { MyGroupComponent } from "../my-group/my-group.component";
import { LoadingIndicatorComponent } from "../../utils/loading-indicator/loading-indicator.component";
import { SubscriptionHelper } from "@aht/frappe-client";

@Component({
  selector: "app-my-groups",
  standalone: true,
  templateUrl: "./my-groups.component.html",
  styleUrls: ["./my-groups.component.scss"],
  imports: [MyGroupComponent, LoadingIndicatorComponent],
})
export class MyGroupsComponent implements OnInit, OnDestroy {
  private readonly helper = new SubscriptionHelper();

  myGroups: Group[] = [];
  isLoadingGroups = false;

  constructor(private readonly store: Store<AppState>) {}

  ngOnInit(): void {
    this.helper.addMany(
      this.store.select(GroupSelectors.myGroups).subscribe((data) => {
        this.isLoadingGroups = data.isLoadingGroups;
        this.myGroups = data.myGroups;
      }),
    );
  }

  ngOnDestroy(): void {
    this.helper.unsubscribeAll();
  }
}
