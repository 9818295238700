import { Component, DestroyRef, OnInit, inject, signal } from "@angular/core";
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterOutlet,
} from "@angular/router";
import { filter, switchMap } from "rxjs";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { RouteData } from "./app.routes";
import { MenuComponent } from "./components/navigation/menu/menu.component";
import { FooterComponent } from "./components/navigation/footer/footer.component";
import { Store } from "@ngrx/store";
import { AppState } from "./ngrx/reducers";
import { LanguageService } from "./services/language.service";
import { AuthenticationSelectors } from "./ngrx/selectors";

@Component({
  selector: "app-root",
  standalone: true,
  templateUrl: "./app.component.html",
  styles: [],
  imports: [RouterOutlet, MenuComponent, FooterComponent],
})
export class AppComponent implements OnInit {
  hideMenu = signal(false);

  private readonly destroyRef = inject(DestroyRef);

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly store: Store<AppState>,
    private readonly languageService: LanguageService,
  ) {}

  ngOnInit(): void {
    this.interpretRouteData();
    this.setupLanguage();
  }

  private setupLanguage() {
    this.languageService.initLanguage();

    this.store
      .select(AuthenticationSelectors.authentication)
      .subscribe((authentication) => {
        if (
          authentication &&
          authentication.user &&
          authentication.user.language
        ) {
          const language = authentication.user.language;
          console.log(`app.component: useLanguage: ${language}`);
          this.languageService.useLanguage(language);
        }
      });
  }

  private interpretRouteData() {
    // see https://stackoverflow.com/a/77737243

    this.router.events
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter((e) => e instanceof NavigationEnd),
        switchMap(() =>
          this.activatedRoute.firstChild
            ? this.activatedRoute.firstChild.data
            : this.activatedRoute.data,
        ),
      )
      .subscribe((data: RouteData) => {
        this.hideMenu.set(data.hideMenu || false);
      });
  }
}
