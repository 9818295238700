import {
  Component,
  OnDestroy,
  OnInit,
  computed,
  effect,
  input,
  signal,
} from "@angular/core";
import { Group } from "src/app/models/group/group";
import { ProgressIndicatorComponent } from "../../common/progress-indicator/progress-indicator.component";
import {
  AppResizeEvent,
  ResizeDirective,
} from "src/app/directives/resize.directive";
import { Router } from "@angular/router";
import { AppState } from "src/app/ngrx/reducers";
import { Store, compose } from "@ngrx/store";
import { InstructionSelectors } from "src/app/ngrx/selectors";

import { toObservable } from "@angular/core/rxjs-interop";
import { filter } from "rxjs/operators";
import { SubscriptionHelper } from "@aht/frappe-client";

@Component({
  selector: "app-my-group",
  standalone: true,
  templateUrl: "./my-group.component.html",
  styles: ``,
  imports: [ProgressIndicatorComponent, ResizeDirective],
})
export class MyGroupComponent implements OnDestroy {
  size: "md" | "lg" = "lg";

  group = input.required<Group>();

  totalInstructions = signal(0);
  progress = signal(0);

  variant = input<"summary" | "full">("summary");

  private readonly helper = new SubscriptionHelper();

  constructor(
    private readonly router: Router,
    private readonly store: Store<AppState>,
  ) {
    this.helper.add(
      toObservable(this.group).subscribe((group) => {
        // console.log("group  -> select", group);

        this.helper.add(
          this.store
            .select(
              InstructionSelectors.myInstructionsAndMyConfirmedInstructionsForGroup(
                group.name,
              ),
            )
            .pipe(
              filter(
                (data) =>
                  !data.isLoadingMyConfirmedInstructions &&
                  !data.isLoadingMyInstructions,
              ),
            )
            .subscribe((data) => {
              // console.log(
              //   `group(${group.name}): set progress to ${data.myConfirmedInstructions.length}, set totalInstructions to ${data.myInstructions.length + data.myConfirmedInstructions.length}`,
              // );
              this.totalInstructions.set(
                data.myInstructions.length +
                  data.myConfirmedInstructions.length,
              );
              this.progress.set(data.myConfirmedInstructions.length);
            }),
        );
      }),
    );
  }

  ngOnDestroy(): void {
    this.helper.unsubscribeAll();
  }

  handleResize(event: AppResizeEvent) {
    // TODO: use tailwind's breakpoints
    if (event.width < 200) {
      this.size = "md";
    } else {
      this.size = "lg";
    }
  }

  openGroupDetails() {
    this.router.navigate(["groups", this.group().name]);
  }
}
