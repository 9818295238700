import {
  Directive,
  ElementRef,
  EventEmitter,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";

@Directive({
  selector: "[appResize]",
  standalone: true,
})
export class ResizeDirective implements OnInit, OnDestroy {
  @Output()
  resized = new EventEmitter<AppResizeEvent>();

  private readonly resizeObserver;

  constructor(
    private readonly host: ElementRef<HTMLElement>,
    private ngZone: NgZone,
  ) {
    this.resizeObserver = new ResizeObserver((entries) => {
      if (entries.length == 1) {
        this.ngZone.run(() => {
          this.resized.emit({
            width: entries[0].contentRect.width,
            height: entries[0].contentRect.height,
            x: entries[0].contentRect.x,
            y: entries[0].contentRect.y,
          });
        });
      }
    });
  }

  ngOnInit() {
    this.resizeObserver.observe(this.host.nativeElement);
  }

  ngOnDestroy() {
    this.resizeObserver.disconnect();
  }
}

export interface AppResizeEvent {
  width: number;
  height: number;
  x: number;
  y: number;
}
