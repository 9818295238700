<div class="mx-auto w-4/5">
  <div class="my-4">
    <app-my-groups></app-my-groups>
  </div>

  <div class="mb-4">
    <app-my-instructions></app-my-instructions>
    <!-- <app-my-confirmed-instructions></app-my-confirmed-instructions> -->
  </div>
</div>
