"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FrappeApiHelper = void 0;
const conversion_extensions_1 = require("./conversion-extensions");
class FrappeApiHelper {
    crud;
    method;
    constructor(crud, method) {
        this.crud = crud;
        this.method = method;
    }
    getSingle(wrapper) {
        const doctype = wrapper.conversion().docType();
        const result = this.crud.querySingle(doctype, doctype);
        const conversionExtension = new conversion_extensions_1.ConversionExtension(wrapper.conversion());
        return conversionExtension.convertObservableSingle(result);
    }
    get(name, wrapper) {
        const result = this.crud.querySingle(name, wrapper.conversion().docType());
        const conversionExtension = new conversion_extensions_1.ConversionExtension(wrapper.conversion());
        return conversionExtension.convertObservableSingle(result);
    }
    getList(wrapper, options) {
        const fields = wrapper
            .conversion()
            .fieldMappings()
            .map((m) => m.frappeName);
        const docType = wrapper.conversion().docType();
        const result = this.crud.queryAll(docType, fields, options);
        const conversionExtension = new conversion_extensions_1.ConversionExtension(wrapper.conversion());
        return conversionExtension.convertObservableMany(result);
    }
    create(data, wrapper) {
        const dto = wrapper.conversion().convertToFrappe(data);
        const result = this.crud.create(dto, wrapper.conversion().docType());
        const conversionExtension = new conversion_extensions_1.ConversionExtension(wrapper.conversion());
        return conversionExtension.convertObservableSingle(result);
    }
    update(name, data, wrapper) {
        const dto = wrapper.conversion().convertToFrappe(data);
        const result = this.crud.update(name, dto, wrapper.conversion().docType());
        const conversionExtension = new conversion_extensions_1.ConversionExtension(wrapper.conversion());
        return conversionExtension.convertObservableSingle(result);
    }
    delete(name, wrapper) {
        return this.crud.delete(name, wrapper.conversion().docType());
    }
    callWithResult(conversion, options) {
        const result = this.method.callWithResult(options);
        const conversionExtension = new conversion_extensions_1.ConversionExtension(conversion);
        return conversionExtension.convertObservableSingle(result);
    }
    callWithOptionalResult(conversion, options) {
        const result = this.method.callWithResult(options);
        const conversionExtension = new conversion_extensions_1.ConversionExtension(conversion);
        return conversionExtension.convertObservableOptionalSingle(result);
    }
    callWithResults(conversion, options) {
        const result = this.method.callWithResults(options);
        const conversionExtension = new conversion_extensions_1.ConversionExtension(conversion);
        return conversionExtension.convertObservableMany(result);
    }
    call(options) {
        const result = this.method.call(options);
        return result;
    }
}
exports.FrappeApiHelper = FrappeApiHelper;
