import { Component } from "@angular/core";
import { LoginComponent } from "../login/login.component";
import { ThemeSwitcherComponent } from "../../utils/theme-switcher/theme-switcher.component";

@Component({
  selector: "app-login-page",
  standalone: true,
  templateUrl: "./login-page.component.html",
  styles: ``,
  imports: [LoginComponent, ThemeSwitcherComponent],
})
export class LoginPageComponent {}
