import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Store } from "@ngrx/store";
import { Group } from "src/app/models/group/group";
import { GroupActions, InstructionActions } from "src/app/ngrx/actions";
import { AppState } from "src/app/ngrx/reducers";
import { MyGroupDetailsComponent } from "../my-group-details/my-group-details.component";

@Component({
  selector: "app-my-group-details-page",
  standalone: true,
  templateUrl: "./my-group-details-page.component.html",
  styles: ``,
  imports: [MyGroupDetailsComponent],
})
export class MyGroupDetailsPageComponent {
  group?: Group;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly store: Store<AppState>,
  ) {}

  ngOnInit(): void {
    const groupName = this.activatedRoute.snapshot.paramMap.get("name") || "";
    this.store.dispatch(GroupActions.loadMyGroupDetails({ name: groupName }));
    this.store.dispatch(InstructionActions.loadMyInstructions());
    this.store.dispatch(InstructionActions.loadMyConfirmedInstructions());
  }
}
