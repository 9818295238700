import { ActionReducer, ActionReducerMap, MetaReducer } from "@ngrx/store";
import {
  AuthenticationState,
  authenticationReducer,
} from "./authentication.reducer";
import { GroupState, groupReducer } from "./group.reducer";
import { localStorageSync } from "ngrx-store-localstorage";
import { InstructionState, instructionReducer } from "./instruction.reducer";

export interface AppState {
  authentication: AuthenticationState;
  group: GroupState;
  instruction: InstructionState;
}

export const REDUCERS: ActionReducerMap<AppState> = {
  authentication: authenticationReducer,
  group: groupReducer,
  instruction: instructionReducer,
};

export function localStorageSyncReducer(
  reducer: ActionReducer<AppState>,
): ActionReducer<AppState> {
  return localStorageSync({
    keys: [
      { authentication: ["user"] },
      {
        notifications: [
          "loadedSettings",
          "settings",
          "pushSettings",
          "pushNotificationSubscription",
        ],
      },
    ],

    rehydrate: true,
  })(reducer);
}

export const META_REDUCERS: Array<MetaReducer<AppState>> = [
  localStorageSyncReducer,
];
