<div class="flex min-h-dvh flex-col">
  @if (!hideMenu()) {
    <app-menu></app-menu>
  }
  <div class="flex-grow">
    <router-outlet />
  </div>

  @if (!hideMenu()) {
    <app-footer></app-footer>
  }
</div>
