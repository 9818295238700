<div class="my-4 flex flex-col">
  <app-loading-indicator
    [isLoading]="isLoadingMyGroupDetails"
  ></app-loading-indicator>

  @if (myGroupDetails) {
    <app-my-group [group]="myGroupDetails" [variant]="'full'"></app-my-group>
    <app-my-instructions [forGroup]="myGroupDetails"></app-my-instructions>
    <app-my-confirmed-instructions
      [forGroup]="myGroupDetails"
    ></app-my-confirmed-instructions>
  }
</div>
