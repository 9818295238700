<div
  #progress
  class="radial-progress swap swap-rotate transition-colors duration-700"
  role="progressbar"
  [class.swap-active]="percentage() == 100"
>
  <div class="swap-on flex items-center justify-center">
    <ng-icon name="lucideCheckCheck" [size]="iconSize()"></ng-icon>
  </div>
  <div class="swap-off">
    @if (variant() == "percentage") {
      {{ percentage() }}%
    } @else {
      {{ value() }} / {{ max() }}
    }
  </div>
</div>
