import { Component } from "@angular/core";
import { LogoutComponent } from "../../authentication/logout/logout.component";
import { MyGroupsComponent } from "../../group/my-groups/my-groups.component";
import { ProgressIndicatorComponent } from "../../common/progress-indicator/progress-indicator.component";
import { MyInstructionsComponent } from "../my-instructions/my-instructions.component";
import { TranslateModule } from "@ngx-translate/core";
import { AppState } from "src/app/ngrx/reducers";
import { Store } from "@ngrx/store";
import { GroupActions, InstructionActions } from "src/app/ngrx/actions";
import { MyConfirmedInstructionsComponent } from "../my-confirmed-instructions/my-confirmed-instructions.component";

@Component({
  selector: "app-instruction-page",
  standalone: true,
  templateUrl: "./instruction-page.component.html",
  styles: ``,
  imports: [
    LogoutComponent,
    MyGroupsComponent,
    ProgressIndicatorComponent,
    MyInstructionsComponent,
    TranslateModule,
    MyConfirmedInstructionsComponent,
  ],
})
export class InstructionPageComponent {
  constructor(private readonly store: Store<AppState>) {
    this.store.dispatch(GroupActions.loadMyGroups());

    this.store.dispatch(InstructionActions.loadMyInstructions());
    this.store.dispatch(InstructionActions.loadMyConfirmedInstructions());
  }
}
