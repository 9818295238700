import { Component, input } from "@angular/core";

@Component({
  selector: "app-loading-indicator",
  standalone: true,
  imports: [],
  templateUrl: "./loading-indicator.component.html",
  styles: ``,
})
export class LoadingIndicatorComponent {
  isLoading = input<boolean>(false);
}
