import { createSelector } from "@ngrx/store";
import { AppState } from "../reducers";

const instructionFeature = (state: AppState) => state.instruction;

export const myInstructions = createSelector(instructionFeature, (state) => ({
  myInstructions: state.myInstructions,
  isLoadingMyInstructions: state.isLoadingMyInstructions,
}));

export const myConfirmedInstructions = createSelector(
  instructionFeature,
  (state) => ({
    myConfirmedInstructions: state.myConfirmedInstructions,
    isLoadingMyConfirmedInstructions: state.isLoadingMyConfirmedInstructions,
  }),
);

export const myInstructionsAndMyConfirmedInstructionsForGroup = (
  group: string,
) => {
  return createSelector(instructionFeature, (state) => ({
    myInstructions: state.myInstructions.filter((i) => i.group == group),
    isLoadingMyInstructions: state.isLoadingMyInstructions,
    myConfirmedInstructions: state.myConfirmedInstructions.filter(
      (i) => i.group == group,
    ),
    isLoadingMyConfirmedInstructions: state.isLoadingMyConfirmedInstructions,
  }));
};

export const myInstructionsForGroup = (group: string) => {
  return createSelector(instructionFeature, (state) => ({
    myInstructions: state.myInstructions.filter((i) => i.group == group),
    isLoadingMyInstructions: state.isLoadingMyInstructions,
  }));
};

export const myConfirmedInstructionsForGroup = (group: string) => {
  return createSelector(instructionFeature, (state) => ({
    myConfirmedInstructions: state.myConfirmedInstructions.filter(
      (i) => i.group == group,
    ),
    isLoadingMyConfirmedInstructions: state.isLoadingMyConfirmedInstructions,
  }));
};

export const myInstruction = createSelector(instructionFeature, (state) => ({
  myInstruction: state.myInstruction,
  isLoadingMyInstruction: state.isLoadingMyInstruction,
}));

export const myInstructionConfirmation = createSelector(
  instructionFeature,
  (state) => ({
    isConfirmingMyInstruction: state.isConfirmingMyInstruction,
    myInstructionConfirmation: state.myInstructionConfirmation,
    isLoadingMyInstructionConfirmation:
      state.isLoadingMyInstructionConfirmation,
  }),
);
