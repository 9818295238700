import { createAction, props } from "@ngrx/store";
import { Group } from "src/app/models/group/group";

export const loadMyGroups = createAction("[Group] load my groups");

export const loadedMyGroups = createAction(
  "[Group] loaded my groups",
  props<{
    groups: Group[];
  }>(),
);

export const loadMyGroupDetails = createAction(
  "[Group] load my group details",
  props<{
    name: string;
  }>(),
);

export const loadedMyGroupDetails = createAction(
  "[Group] loaded my group details",
  props<{
    group: Group;
  }>(),
);
