import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from "@angular/router";
import { Store } from "@ngrx/store";
import { Observable, map, tap } from "rxjs";
import { AppState } from "../ngrx/reducers";
import { AuthenticationSelectors } from "../ngrx/selectors";

@Injectable({
  providedIn: "root",
})
export class SkipLoginGuard {
  constructor(private readonly store: Store<AppState>, public router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.store.select(AuthenticationSelectors.isLoggedIn).pipe(
      tap((isLoggedIn) => {
        if (isLoggedIn) {
          this.router.navigate(["/"]);
        }
      }),
      map((_) => true) // we can always activate, but sometimes we redirect (see above)
    );
  }
}
