<div class="">
  @if (
    !isLoadingMyConfirmedInstructions && myConfirmedInstructions.length > 0
  ) {
    <h3>{{ "instruction.my-confirmed-instructions.title" | translate }}</h3>
  }

  <app-instruction-list
    [instructions]="myConfirmedInstructions"
    [isLoading]="isLoadingMyConfirmedInstructions"
    (open)="open($event)"
  ></app-instruction-list>
</div>
