<div class="card w-full rounded-lg outline outline-2 outline-base-300">
  <figure>
    <img alt="logo" src="/assets/logo/logo.png" class="w-48 md:w-96" />
  </figure>
  <div class="card-body">
    <form (submit)="login()" [formGroup]="form">
      <input
        type="text"
        class="input input-bordered mb-4 w-full max-w-xs"
        placeholder="E-Mail"
        [formControl]="email"
      />
      <input
        type="password"
        class="input input-bordered mb-4 w-full max-w-xs"
        placeholder="Password"
        [formControl]="password"
      />

      <div class="card-actions justify-end">
        @if (hasLoginError) {
          <p class="text-error">
            {{ "authentication.login.error" | translate }}
          </p>
        }
        <button
          class="btn btn-primary"
          type="submit"
          [disabled]="form.invalid || isLoggingIn"
        >
          @if (isLoggingIn) {
            <span class="loading loading-spinner"></span>
          }
          {{ "authentication.login.login" | translate }}
        </button>
      </div>
    </form>
  </div>
</div>
