import { createReducer, on } from "@ngrx/store";
import { UserInfo } from "src/app/models/authentication/user-info";
import { AuthenticationActions } from "../actions";

export interface AuthenticationState {
  user?: UserInfo;
  isLoggingIn: boolean;
  hasLoginError: boolean;
}

const initialState = {
  isLoggingIn: false,
  hasLoginError: false,
} as AuthenticationState;

export const authenticationReducer = createReducer(
  initialState,
  on(AuthenticationActions.login, () => ({
    ...initialState,
    isLoggingIn: true,
    hasLoginError: false
  })),
  on(AuthenticationActions.loginFailed, () => ({
    ...initialState,
    hasLoginError: true
  })),
  on(AuthenticationActions.loggedIn, (state, action) => ({
    ...state,
    isLoggingIn: false,
    user: action.userInfo,
  })),
  on(AuthenticationActions.logout, () => initialState),
);
