import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Store } from "@ngrx/store";
import { tap } from "rxjs/operators";
import { Observable } from "rxjs";
import { AppState } from "../ngrx/reducers";
import { AuthenticationSelectors } from "../ngrx/selectors";

@Injectable({
  providedIn: "root",
})
export class AuthGuard {
  constructor(
    private readonly store: Store<AppState>,
    public router: Router,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.store.select(AuthenticationSelectors.isLoggedIn).pipe(
      tap((isLoggedIn) => {
        // console.log(`AuthGuard: ${isLoggedIn}`);
        if (!isLoggedIn) {
          this.router.navigate(["/login"]);
        }
      }),
    );
  }
}
