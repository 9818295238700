import { createSelector } from "@ngrx/store";
import { AppState } from "../reducers";
import { AuthenticationState } from "../reducers/authentication.reducer";

const authenticationFeature = (state: AppState) => state.authentication;

export const authentication = createSelector(
  authenticationFeature,
  (state) => ({
    isLoggingIn: state.isLoggingIn,
    user: state.user,
    hasLoginError: state.hasLoginError,
  }),
);

const loginIsNotExpired = (us: AuthenticationState) =>
  (us.user && (us.user.expiresIn || new Date()).valueOf() > Date.now()) ||
  false;

export const isLoggedIn = createSelector(authenticationFeature, (state) =>
  loginIsNotExpired(state),
);
