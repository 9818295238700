"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UrlSanitizer = void 0;
class UrlSanitizer {
    tmp = "";
    addUrlPart(part) {
        if (part) {
            if (this.tmp.endsWith("/")) {
                this.tmp = this.tmp.substr(0, this.tmp.length - 1);
            }
            if (part.startsWith("/")) {
                part = part.substring(1);
            }
            this.tmp = this.tmp.length > 0 ? this.tmp + "/" + part : part;
        }
        return this;
    }
    url() {
        return this.tmp;
    }
}
exports.UrlSanitizer = UrlSanitizer;
