import { Component, OnDestroy, OnInit, input } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { Instruction } from "src/app/models/instruction/instruction";
import { AppState } from "src/app/ngrx/reducers";
import { InstructionSelectors } from "src/app/ngrx/selectors";
import { InstructionListComponent } from "../instruction-list/instruction-list.component";
import { TranslateModule } from "@ngx-translate/core";
import { Group } from "src/app/models/group/group";
import { toObservable } from "@angular/core/rxjs-interop";
import { mergeMap } from "rxjs";
import { SubscriptionHelper } from "@aht/frappe-client";

@Component({
  selector: "app-my-confirmed-instructions",
  standalone: true,
  templateUrl: "./my-confirmed-instructions.component.html",
  styles: ``,
  imports: [InstructionListComponent, TranslateModule],
})
export class MyConfirmedInstructionsComponent implements OnInit, OnDestroy {
  private readonly helper = new SubscriptionHelper();

  isLoadingMyConfirmedInstructions: boolean = false;
  myConfirmedInstructions: Instruction[] = [];

  forGroup = input<Group>();
  forGroupObservable$ = toObservable(this.forGroup);

  constructor(
    private readonly store: Store<AppState>,
    private readonly router: Router,
  ) {}

  ngOnInit(): void {
    this.helper.add(
      this.forGroupObservable$
        .pipe(
          mergeMap((group) => {
            if (group) {
              return this.store.select(
                InstructionSelectors.myConfirmedInstructionsForGroup(
                  group.name,
                ),
              );
            } else {
              return this.store.select(
                InstructionSelectors.myConfirmedInstructions,
              );
            }
          }),
        )
        .subscribe((data) => {
          this.isLoadingMyConfirmedInstructions =
            data.isLoadingMyConfirmedInstructions;
          this.myConfirmedInstructions = data.myConfirmedInstructions;
        }),
    );
  }

  ngOnDestroy(): void {
    this.helper.unsubscribeAll();
  }

  open(instruction: Instruction) {
    this.router.navigate(["instructions", instruction.name]);
  }
}
