import { Component, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { AppState } from "src/app/ngrx/reducers";
import { GroupSelectors } from "src/app/ngrx/selectors";

import { MyGroupComponent } from "../my-group/my-group.component";
import { Group } from "src/app/models/group/group";
import { MyInstructionsComponent } from "../../instruction/my-instructions/my-instructions.component";
import { MyConfirmedInstructionsComponent } from "../../instruction/my-confirmed-instructions/my-confirmed-instructions.component";
import { LoadingIndicatorComponent } from "../../utils/loading-indicator/loading-indicator.component";
import { SubscriptionHelper } from "@aht/frappe-client";

@Component({
  selector: "app-my-group-details",
  standalone: true,
  templateUrl: "./my-group-details.component.html",
  styles: ``,
  imports: [
    MyGroupComponent,
    MyInstructionsComponent,
    MyConfirmedInstructionsComponent,
    LoadingIndicatorComponent,
  ],
})
export class MyGroupDetailsComponent implements OnInit, OnDestroy {
  myGroupDetails?: Group;
  isLoadingMyGroupDetails: boolean = false;

  private readonly helper = new SubscriptionHelper();

  constructor(private readonly store: Store<AppState>) {}

  ngOnInit(): void {
    this.helper.addMany(
      this.store.select(GroupSelectors.myGroupDetails).subscribe((data) => {
        this.myGroupDetails = data.myGroupDetails;
        this.isLoadingMyGroupDetails = data.isLoadingMyGroupDetails;
      }),
    );
  }

  ngOnDestroy(): void {
    this.helper.unsubscribeAll();
  }
}
