import { Injectable } from "@angular/core";

import { UserInfo } from "../models/authentication/user-info";
import { Observable } from "rxjs";
import {
  FieldMapping,
  mappingBuilder,
  mappingHelper,
  FrappeApiHelper,
} from "@aht/frappe-client";

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  private customExpiresInMapper = {
    frappeName: "expires_in",
    name: "expiresIn",
    map: (field) => {
      const expiresInDuration = "" + field;
      const regex = /(\d+):(\d+)/;
      const match = expiresInDuration.match(regex);

      if (match && match.length == 3) {
        const hours = match[1];
        const minutes = match[2];
        return new Date(
          Date.now() +
            parseInt(hours) * 60 * 60 * 1000 +
            parseInt(minutes) * 60 * 1000,
        );
      } else {
        return new Date(Date.now() + 4 * 60 * 60 * 1000); // fallback: 4 hours in the future
      }
    },
    mapToFrappe: (_) => {
      throw "customExpiresInMapper does not implement mapToFrappe()";
    },
  } as FieldMapping;

  private readonly wrapper = mappingBuilder<UserInfo>("DOES NOT MATTER", [
    mappingHelper.stringMapper("api_key", "apiKey"),
    mappingHelper.stringMapper("api_secret", "apiSecret"),
    mappingHelper.stringMapper("name"),
    mappingHelper.stringMapper("email"),
    mappingHelper.stringMapper("language"),
    mappingHelper.stringMapper("first_name", "firstName"),
    mappingHelper.stringMapper("last_name", "lastName"),
    mappingHelper.stringMapper("full_name", "fullName"),
    mappingHelper.listMapper("roles"),
    this.customExpiresInMapper,
  ]);

  constructor(private readonly helper: FrappeApiHelper) {}

  login(email: string, password: string): Observable<UserInfo> {
    return this.helper.callWithResult(this.wrapper.conversion(), {
      method: "sar.controllers.authentication_controller.login",
      type: "POST",
      body: {
        username: email,
        password: password,
      },
    });
  }
}
