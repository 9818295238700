<div
  class="flex w-full flex-col items-center bg-neutral p-10 text-neutral-content"
>
  <footer class="footer w-full md:w-4/5">
    <aside>
      <img src="/assets/logo/logo.png" class="w-24" />
      <p>
        SAR Anlagenbau GmbH<br />
        3363 Hausmening<br />
        Bahnhofstraße 34
      </p>
    </aside>
    <nav>
      <h6 class="footer-title">
        {{ "navigation.footer.contact" | translate }}
      </h6>
      <a href="mailto:office@sar-anlagenbau.at" class="text-neutral-content"
        >office&#64;sar-anlagenbau.at</a
      >
      <a href="tel:+437475202020" class="text-neutral-content">
        +43 (0) 7475 20202-0
      </a>
    </nav>
  </footer>
  <app-version></app-version>
</div>
