import { Component, ElementRef, ViewChild } from "@angular/core";

@Component({
  selector: "app-theme-switcher",
  standalone: true,
  imports: [],
  templateUrl: "./theme-switcher.component.html",
  styles: ``,
})
export class ThemeSwitcherComponent {
  @ViewChild("themeSwitcher")
  progress?: ElementRef<HTMLInputElement>;

  themeSwitched(event: Event) {
    // console.log(event);
    // console.log(this.progress?.nativeElement.value);
  }
}
