<div class="flex h-full flex-row">
  @if (variant() == "full") {
    <div
      class="card w-full cursor-pointer outline outline-1 outline-primary"
      (click)="openGroupDetails()"
    >
      <figure appResize (resized)="handleResize($event)">
        <app-progress-indicator
          [value]="progress()"
          [max]="totalInstructions()"
          variant="xOfY"
          [size]="size"
          class="text-primary"
        ></app-progress-indicator>
      </figure>
      <div class="card-body p-2">
        <h2 class="card-title hyphens-auto break-normal text-primary" lang="de">
          {{ group().title }}
        </h2>

        <div
          class="mb-4 truncate hyphens-auto whitespace-pre-line text-neutral-800"
          lang="de"
        >
          {{ group().description }}
        </div>
      </div>
    </div>
  } @else {
    <div
      class="card w-full cursor-pointer outline outline-1 outline-primary"
      (click)="openGroupDetails()"
    >
      <figure appResize (resized)="handleResize($event)">
        <app-progress-indicator
          [value]="progress()"
          [max]="totalInstructions()"
          variant="xOfY"
          size="md"
          class="text-primary"
        ></app-progress-indicator>
      </figure>
      <div class="card-body p-2">
        <h3 class="card-title hyphens-auto break-normal text-primary" lang="de">
          {{ group().title }}
        </h3>
      </div>
    </div>
  }
</div>
