import { createReducer, on } from "@ngrx/store";
import { GroupActions } from "../actions";
import { Group } from "src/app/models/group/group";

export interface GroupState {
  myGroups: Group[];
  isLoadingGroups: boolean;
  myGroupDetails?: Group;
  isLoadingMyGroupDetails: boolean;
}

const initialState = {
  isLoadingGroups: false,
  myGroups: [],
  isLoadingMyGroupDetails: false,
  myGroupDetails: undefined,
} as GroupState;

export const groupReducer = createReducer(
  initialState,
  on(GroupActions.loadMyGroups, () => ({
    ...initialState,
    myGroups: [],
    isLoadingGroups: true,
  })),
  on(GroupActions.loadedMyGroups, (state, action) => ({
    ...state,
    isLoadingGroups: false,
    myGroups: action.groups,
  })),
  on(GroupActions.loadMyGroupDetails, (state, action) => ({
    ...state,
    myGroupDetails: undefined,
    isLoadingMyGroupDetails: true,
  })),
  on(GroupActions.loadedMyGroupDetails, (state, action) => ({
    ...state,
    myGroupDetails: action.group,
    isLoadingMyGroupDetails: false,
  })),
);
