import { Routes } from "@angular/router";
import { InstructionPageComponent } from "./components/instruction/instruction-page/instruction-page.component";
import { LoginPageComponent } from "./components/authentication/login-page/login-page.component";
import { AuthGuard } from "./guards/auth-guard";
import { SkipLoginGuard } from "./guards/skip-login.guard";
import { InstructionDetailPageComponent } from "./components/instruction/instruction-detail-page/instruction-detail-page.component";
import { MyGroupsComponent } from "./components/group/my-groups/my-groups.component";
import { MyGroupDetailsPageComponent } from "./components/group/my-group-details-page/my-group-details-page.component";

export interface RouteData {
  hideMenu?: boolean;
}

export const routes: Routes = [
  {
    path: "instructions",
    pathMatch: "full",
    component: InstructionPageComponent,
    data: {},
    canActivate: [AuthGuard],
  },
  {
    path: "instructions/:name",
    pathMatch: "full",
    component: InstructionDetailPageComponent,
    data: {},
    canActivate: [AuthGuard],
  },
  {
    path: "groups/:name",
    pathMatch: "full",
    component: MyGroupDetailsPageComponent,
    data: {},
    canActivate: [AuthGuard],
  },
  {
    path: "login",
    pathMatch: "full",
    canActivate: [SkipLoginGuard],
    component: LoginPageComponent,
    data: {
      hideMenu: true, // is only passed outside of the route
      // https://stackoverflow.com/a/77737243
    },
  },
  {
    path: "**",
    redirectTo: "/instructions",
  },
];
