import { createAction, props } from "@ngrx/store";
import { Instruction } from "src/app/models/instruction/instruction";
import { InstructionConfirmation } from "src/app/models/instruction/instruction-confirmation";

export const loadMyInstructions = createAction(
  "[Instruction] load my instructions",
);

export const loadedMyInstructions = createAction(
  "[Instruction] loaded my instructions",
  props<{
    instructions: Instruction[];
  }>(),
);

export const loadMyConfirmedInstructions = createAction(
  "[Instruction] load my confirmed instructions",
);

export const loadedMyConfirmedInstructions = createAction(
  "[Instruction] loaded my confirmed instructions",
  props<{
    confirmedInstructions: Instruction[];
  }>(),
);

export const loadMyInstruction = createAction(
  "[Instruction] load my instruction",
  props<{
    name: string;
  }>(),
);

export const loadedMyInstruction = createAction(
  "[Instruction] loaded my instruction",
  props<{
    instruction: Instruction;
  }>(),
);

export const loadMyInstructionConfirmation = createAction(
  "[Instruction] load confirmation",
  props<{
    instruction: Instruction;
  }>(),
);

export const loadedMyInstructionConfirmation = createAction(
  "[Instruction] loaded confirmation",
  props<{
    confirmation?: InstructionConfirmation;
  }>(),
);

export const confirmMyInstruction = createAction(
  "[Instruction] confirm",
  props<{
    instruction: Instruction;
  }>(),
);

export const confirmedMyInstruction = createAction(
  "[Instruction] confirmed",
  props<{
    confirmation: InstructionConfirmation;
  }>(),
);

export const confirmMyInstructionFailed = createAction(
  "[Instruction] confirm failed",
  props<{
    error?: any;
  }>(),
);
