import { JsonPipe, DatePipe } from "@angular/common";
import { Component, input, output } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { Instruction } from "src/app/models/instruction/instruction";
import { LoadingIndicatorComponent } from "../../utils/loading-indicator/loading-indicator.component";

@Component({
  selector: "app-instruction-list",
  standalone: true,
  templateUrl: "./instruction-list.component.html",
  styleUrls: ["./instruction-list.component.scss"],
  imports: [JsonPipe, TranslateModule, DatePipe, LoadingIndicatorComponent],
})
export class InstructionListComponent {
  isLoading = input.required<boolean>();
  instructions = input.required<Instruction[]>();

  open = output<Instruction>();

  constructor() {}
}
