import { Actions, createEffect, ofType } from "@ngrx/effects";
import { AuthenticationActions } from "../actions";
import { catchError, map, mergeMap, tap } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { AuthenticationService } from "src/app/services/authentication.service";
import { of } from "rxjs";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class AuthenticationEffects {
  loadPlants$ = createEffect(() =>
    this.actions.pipe(
      ofType(AuthenticationActions.login),
      mergeMap((action) =>
        this.authenticationService.login(action.email, action.password).pipe(
          map((r) => AuthenticationActions.loggedIn({ userInfo: r })),
          catchError((error) => of(AuthenticationActions.loginFailed(error))),
        ),
      ),
    ),
  );

  triggerLogout$ = createEffect(() =>
    this.actions.pipe(
      ofType(AuthenticationActions.logout),
      map((_) => AuthenticationActions.loggedOut()),
    ),
  );

  navigateToLoginPageOnLogout$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(AuthenticationActions.loggedOut),
        tap((_) => this.router.navigate(["/login"])),
      ),
    { dispatch: false },
  );

  navigateHomeAfterLogin$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(AuthenticationActions.loggedIn),
        tap((_) => this.router.navigate(["/"])),
      ),
    { dispatch: false },
  );

  constructor(
    private readonly actions: Actions,
    private readonly authenticationService: AuthenticationService,
    private readonly router: Router,
  ) {}
}
