import { Actions, createEffect, ofType } from "@ngrx/effects";
import { GroupActions } from "../actions";
import { map, mergeMap } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { GroupService } from "src/app/services/group.service";

@Injectable()
export class GroupEffects {
  loadMyGroups$ = createEffect(() =>
    this.actions.pipe(
      ofType(GroupActions.loadMyGroups),
      mergeMap((_) =>
        this.groupService
          .loadMyGroups()
          .pipe(map((groups) => GroupActions.loadedMyGroups({ groups }))),
      ),
    ),
  );

  loadMyGroupDetails$ = createEffect(() =>
    this.actions.pipe(
      ofType(GroupActions.loadMyGroupDetails),
      mergeMap((action) =>
        this.groupService
          .loadMyGroupDetails(action.name)
          .pipe(map((group) => GroupActions.loadedMyGroupDetails({ group }))),
      ),
    ),
  );

  constructor(
    private readonly actions: Actions,
    private readonly groupService: GroupService,
  ) {}
}
