import { Component } from "@angular/core";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-version",
  standalone: true,
  imports: [],
  templateUrl: "./version.component.html",
  styles: ``,
})
export class VersionComponent {
  version = environment.version;
  versionName = environment.versionName;
}
