<app-loading-indicator [isLoading]="isLoading()"></app-loading-indicator>
<div class="instruction-list">
  @for (instruction of instructions(); track $index) {
    <div class="card outline outline-1 outline-base-300">
      <div class="card-body p-4">
        <div class="card-title hyphens-auto break-normal" lang="de">
          {{ instruction.title }}
        </div>
        <div class="text-sm text-neutral-600">
          {{ instruction.modified | date: ("common.dateFormat" | translate) }}
        </div>
        <div class="flex-1">
          <!-- spacer -->
        </div>
        <div class="flex flex-col items-center">
          <button
            class="btn btn-primary w-full md:w-48"
            (click)="open.emit(instruction)"
          >
            {{ "instruction.instruction-list.open" | translate }}
          </button>
        </div>
      </div>
    </div>
  }
</div>
