"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.singleDocBuilder = exports.singleDocMappingBuilderWithDefaultMappings = exports.mappingBuilder = exports.mappingBuilderWithDefaultMappings = exports.singleDocDefaultMappings = exports.frappeDefaultMappings = exports.mappingHelper = exports.BaseConversion = void 0;
const unit_service_1 = require("./unit.service");
function getValueUnchecked(data, key) {
    return data[key];
}
// helper
function getValue(data, key) {
    return data[key];
}
function setValue(into, key, value) {
    into[key] = value;
}
// also move to a custom file
class BaseConversion {
    type;
    mappings;
    constructor(type, mappings) {
        this.type = type;
        this.mappings = mappings;
    }
    docType() {
        return this.type;
    }
    fieldMappings() {
        return this.mappings;
    }
    convert(from) {
        var result = {};
        this.fieldMappings().forEach((mapping) => {
            const field = getValue(from, mapping.frappeName);
            setValue(result, mapping.name, mapping.map(field));
        });
        return result;
    }
    convertToFrappe(appType) {
        var result = {};
        this.fieldMappings().forEach((mapping) => {
            const value = getValueUnchecked(appType, mapping.name);
            console.log(value);
            if (value !== undefined) {
                setValue(result, mapping.frappeName, mapping.mapToFrappe(value));
            }
        });
        return result;
    }
}
exports.BaseConversion = BaseConversion;
class MappingHelper {
    // move to custom file?
    unitService = new unit_service_1.UnitService();
    dateMapper(frappeName, name) {
        return {
            frappeName: frappeName,
            name: name != undefined ? name : frappeName,
            map: (value) => this.unitService.parseApiDate(value),
            mapToFrappe: (value) => this.unitService.dateToApiDate(value),
        };
    }
    optionalDateMapper(frappeName, name) {
        return {
            frappeName: frappeName,
            name: name != undefined ? name : frappeName,
            map: (value) => this.unitService.parseOptionalApiDate(value),
            mapToFrappe: (value) => this.unitService.dateToApiDate(value),
        };
    }
    stringMapper(frappeName, name) {
        return {
            frappeName: frappeName,
            name: name != undefined ? name : frappeName,
            map: (value) => {
                if (value) {
                    return "" + value;
                }
                else {
                    return "";
                }
            },
            mapToFrappe: (value) => value,
        };
    }
    docStatusMapper(frappeName, name) {
        return {
            frappeName: frappeName,
            name: name != undefined ? name : frappeName,
            map: (value) => {
                if (value == 0) {
                    return "draft";
                }
                else if (value == "1") {
                    return "submitted";
                }
                else {
                    return "cancelled";
                }
            },
            mapToFrappe: (value) => {
                const status = value;
                switch (status) {
                    case "draft":
                        return 0;
                    case "submitted":
                        return 1;
                    default:
                        return 2; // cancelled = 2
                }
            },
        };
    }
    booleanMapper(frappeName, name) {
        return {
            frappeName: frappeName,
            name: name != undefined ? name : frappeName,
            map: (value) => value > 0,
            mapToFrappe: (value) => (value ? 1 : 0),
        };
    }
    jsonMapper(frappeName, name) {
        return {
            frappeName: frappeName,
            name: name != undefined ? name : frappeName,
            map: (value) => JSON.parse(value),
            mapToFrappe: (value) => JSON.stringify(value),
        };
    }
    listMapper(frappeName, name) {
        return {
            frappeName: frappeName,
            name: name != undefined ? name : frappeName,
            map: (value) => {
                if (!value || value.length == 0) {
                    return [];
                }
                else {
                    return value;
                }
            },
            mapToFrappe: (_) => {
                throw "list mapper mapToFrappe not implemented";
            },
        };
    }
}
exports.mappingHelper = new MappingHelper();
exports.frappeDefaultMappings = [
    exports.mappingHelper.dateMapper("creation"),
    exports.mappingHelper.dateMapper("modified"),
    exports.mappingHelper.stringMapper("name"),
    exports.mappingHelper.stringMapper("owner"),
];
exports.singleDocDefaultMappings = [
    exports.mappingHelper.dateMapper("modified"),
    exports.mappingHelper.stringMapper("owner"),
];
function mappingBuilderWithDefaultMappings(doctype, mappings) {
    return {
        conversion: () => new BaseConversion(doctype, [...exports.frappeDefaultMappings, ...mappings]),
    };
}
exports.mappingBuilderWithDefaultMappings = mappingBuilderWithDefaultMappings;
function mappingBuilder(doctype, mappings) {
    return {
        conversion: () => new BaseConversion(doctype, mappings),
    };
}
exports.mappingBuilder = mappingBuilder;
function singleDocMappingBuilderWithDefaultMappings(doctype, mappings) {
    return {
        conversion: () => new BaseConversion(doctype, [
            ...exports.singleDocDefaultMappings,
            ...mappings,
        ]),
    };
}
exports.singleDocMappingBuilderWithDefaultMappings = singleDocMappingBuilderWithDefaultMappings;
function singleDocBuilder(doctype, mappings) {
    return {
        conversion: () => new BaseConversion(doctype, mappings),
    };
}
exports.singleDocBuilder = singleDocBuilder;
