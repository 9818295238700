import { Component } from "@angular/core";
import { Store } from "@ngrx/store";
import { TranslateModule } from "@ngx-translate/core";
import { AuthenticationActions } from "src/app/ngrx/actions";
import { AppState } from "src/app/ngrx/reducers";

@Component({
  selector: "app-logout",
  standalone: true,
  imports: [TranslateModule],
  templateUrl: "./logout.component.html",
  styles: ``,
})
export class LogoutComponent {
  constructor(private readonly store: Store<AppState>) {}

  logout() {
    this.store.dispatch(AuthenticationActions.logout());
  }
}
