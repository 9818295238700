<div class="">
  @if (!isLoadingMyInstructions && myInstructions.length > 0) {
    <h3>{{ "instruction.my-instructions.title" | translate }}</h3>
  }

  <app-instruction-list
    [instructions]="myInstructions"
    [isLoading]="isLoadingMyInstructions"
    (open)="open($event)"
  ></app-instruction-list>
</div>
