import { DatePipe, NgTemplateOutlet } from "@angular/common";
import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { TranslateModule } from "@ngx-translate/core";
import { Instruction } from "src/app/models/instruction/instruction";
import { InstructionConfirmation } from "src/app/models/instruction/instruction-confirmation";
import { InstructionActions } from "src/app/ngrx/actions";
import { AppState } from "src/app/ngrx/reducers";
import { InstructionSelectors } from "src/app/ngrx/selectors";
import {
  PDFInfo,
  PdfViewerComponent,
} from "../../common/pdf-viewer/pdf-viewer.component";
import { PDFFileService } from "src/app/services/pdf-file.service";
import {
  AppResizeEvent,
  ResizeDirective,
} from "src/app/directives/resize.directive";
import { LoadingIndicatorComponent } from "../../utils/loading-indicator/loading-indicator.component";
import { SubscriptionHelper } from "@aht/frappe-client";

type DialogActions = "confirm" | "cancel";

@Component({
  selector: "app-instruction-detail",
  standalone: true,
  templateUrl: "./instruction-detail.component.html",
  styles: ``,
  imports: [
    DatePipe,
    TranslateModule,
    PdfViewerComponent,
    ResizeDirective,
    LoadingIndicatorComponent,
    NgTemplateOutlet,
  ],
})
export class InstructionDetailComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  private readonly helper = new SubscriptionHelper();

  isLoadingMyInstruction: boolean = false;
  myInstruction?: Instruction;

  isConfirmingMyInstruction: boolean = false;
  isLoadingMyInstructionConfirmation = false;
  myInstructionConfirmation?: InstructionConfirmation;

  pdfUrl = "";

  pageNumber = -1;

  numPages = 0;

  pdfWidth = 0;
  pdfHeight = 0;

  hasRenderedFirstPage = false;

  constructor(
    private readonly store: Store<AppState>,
    private readonly fileService: PDFFileService,
  ) {}

  ngOnInit(): void {
    this.helper.addMany(
      this.store
        .select(InstructionSelectors.myInstruction)
        .subscribe((data) => {
          this.isLoadingMyInstruction = data.isLoadingMyInstruction;
          this.myInstruction = data.myInstruction;

          if (data.myInstruction) {
            this.pdfUrl = this.fileService.downloadUrlForInstruction(
              data.myInstruction,
            );
          } else {
            this.pdfUrl = "";
          }
        }),

      this.store
        .select(InstructionSelectors.myInstructionConfirmation)
        .subscribe((data) => {
          this.isConfirmingMyInstruction = data.isConfirmingMyInstruction;
          this.myInstructionConfirmation = data.myInstructionConfirmation;
          this.isLoadingMyInstructionConfirmation =
            data.isLoadingMyInstructionConfirmation;
        }),
    );
  }

  ngOnDestroy(): void {
    this.helper.unsubscribeAll();
  }

  ngAfterViewInit(): void {}

  close(dialog: HTMLDialogElement, action: DialogActions) {
    dialog.close(action);
  }

  dialogClosed(dialog: HTMLDialogElement) {
    if (
      this.myInstruction &&
      ("confirm" as DialogActions) == dialog.returnValue
    ) {
      this.store.dispatch(
        InstructionActions.confirmMyInstruction({
          instruction: this.myInstruction,
        }),
      );
    }
  }

  pdfLoaded(pdf: PDFInfo) {
    this.numPages = pdf.numPages;
    this.pageNumber = 1;
  }

  handleFirstPageRendered() {
    this.hasRenderedFirstPage = true;
  }

  handleResized(event: AppResizeEvent) {
    const minDiff = 100;

    const setWidthAndHeight = (width: number, height: number) => {
      if (
        Math.abs(this.pdfHeight - height) > minDiff &&
        Math.abs(this.pdfWidth - width) > minDiff
      ) {
        this.pdfHeight = height;
        this.pdfWidth = width;
      }
    };

    const minHeight = 720;
    const cutoffWith = 1024;
    const width = Math.floor(event.width);
    const height = Math.max(Math.floor(event.height), minHeight); // at the beginning the container has no height, therefore we should at least make the pdf-height "minHeight"

    if (width > cutoffWith) {
      setWidthAndHeight(cutoffWith, (width / height) * cutoffWith);
    } else {
      setWidthAndHeight(width, height);
    }
  }
}
