import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Store } from "@ngrx/store";
import { UserInfo } from "../models/authentication/user-info";
import { AppState } from "../ngrx/reducers";
import { AuthenticationSelectors } from "../ngrx/selectors";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  currentAuth?: UserInfo;

  constructor(private readonly store: Store<AppState>) {
    this.store
      .select(AuthenticationSelectors.authentication)
      .subscribe((data) => {
        this.currentAuth = data.user;
      });
  }

  private shouldAddHeader(request: HttpRequest<unknown>): boolean {
    // the Authorization header can mess up backends where it is not supposed to be sent to (e.g. Rados GW)
    return request.url.startsWith(environment.apiUrl);
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (this.shouldAddHeader(request) && this.currentAuth) {
      request = request.clone({
        setHeaders: {
          Authorization: `token ${this.currentAuth.apiKey}:${this.currentAuth.apiSecret}`,
        },
      });
      return next.handle(request);
    }
    return next.handle(request);
  }
}
