import {
  ApplicationConfig,
  Provider,
  importProvidersFrom,
  isDevMode,
} from "@angular/core";
import { provideRouter } from "@angular/router";
import { routes } from "./app.routes";

import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi,
} from "@angular/common/http";
import { provideEffects } from "@ngrx/effects";
import { EFFECTS } from "./ngrx/effects";
import { META_REDUCERS, REDUCERS } from "./ngrx/reducers";
import { provideStore } from "@ngrx/store";
import { TokenInterceptor } from "./interceptors/token.interceptor";
import { provideStoreDevtools } from "@ngrx/store-devtools";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { environment } from "src/environments/environment";
import {
  FrappeApiHelper,
  FrappeConfiguration,
  FrappeCrudService,
  FrappeMethodService,
} from "@aht/frappe-client";

const frappeClientConfiguration = {
  baseUrl: environment.apiUrl,
} as FrappeConfiguration;

const frappeClientLibraryProviders = [
  {
    provide: FrappeCrudService,
    useFactory: (client: HttpClient) =>
      new FrappeCrudService(client, frappeClientConfiguration),
    deps: [HttpClient],
  },
  {
    provide: FrappeMethodService,
    useFactory: (client: HttpClient) =>
      new FrappeMethodService(client, frappeClientConfiguration),
    deps: [HttpClient],
  },
  {
    provide: FrappeApiHelper,
    useFactory: (crud: FrappeCrudService, method: FrappeMethodService) =>
      new FrappeApiHelper(crud, method),
    deps: [FrappeCrudService, FrappeMethodService],
  },
] as Provider[];

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideStore(REDUCERS, {
      runtimeChecks: {
        strictActionImmutability: true,
        strictActionTypeUniqueness: true,
        strictActionWithinNgZone: true,
        strictStateImmutability: true,
      },
      metaReducers: META_REDUCERS,
    }),
    provideEffects(EFFECTS),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    provideHttpClient(withInterceptorsFromDi()),
    provideStoreDevtools(),
    importProvidersFrom(
      TranslateModule.forRoot({
        defaultLanguage: "de",
        useDefaultLang: true,
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        },
      }),
    ),
    ...frappeClientLibraryProviders,
  ],
};

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}
