import { createAction, props } from "@ngrx/store";
import { UserInfo } from "src/app/models/authentication/user-info";

export const login = createAction(
  "[Authentication] login",
  props<{ email: string; password: string }>()
);

export const loggedIn = createAction(
  "[Authentication] logged in",
  props<{ userInfo: UserInfo }>()
);

export const loginFailed = createAction(
  "[Authentication] login failed",
  props<{ error?: any }>()
);

export const logout = createAction("[Authentication] logout");

export const loggedOut = createAction("[Authentication] loggedOut");
