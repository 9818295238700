import { Component } from "@angular/core";
import {
  FormControl,
  Validators,
  FormGroup,
  ReactiveFormsModule,
} from "@angular/forms";
import { Store } from "@ngrx/store";
import { TranslateModule } from "@ngx-translate/core";
import { AuthenticationActions } from "src/app/ngrx/actions";
import { AppState } from "src/app/ngrx/reducers";
import { AuthenticationSelectors } from "src/app/ngrx/selectors";

@Component({
  selector: "app-login",
  standalone: true,
  imports: [ReactiveFormsModule, TranslateModule],
  templateUrl: "./login.component.html",
  styles: ``,
})
export class LoginComponent {
  isLoggingIn: boolean = false;

  email = new FormControl("", {
    nonNullable: true,
    validators: [
      Validators.required,
      Validators.minLength(1),
      Validators.maxLength(140),
    ],
  });

  password = new FormControl("", {
    nonNullable: true,
    validators: [
      Validators.required,
      Validators.minLength(1),
      Validators.maxLength(140),
    ],
  });

  form = new FormGroup({ email: this.email, password: this.password });
  hasLoginError: boolean = false;

  constructor(private readonly store: Store<AppState>) {
    this.store.select(AuthenticationSelectors.authentication).subscribe((d) => {
      this.isLoggingIn = d.isLoggingIn;
      this.hasLoginError = d.hasLoginError;

      if (!this.isLoggingIn) {
        this.form.enable();
      }
    });
  }

  login() {
    if (this.form.valid) {
      this.store.dispatch(
        AuthenticationActions.login({
          email: this.email.value,
          password: this.password.value,
        }),
      );

      this.password.reset();
      this.form.disable();
    }
  }
}
