import { Actions, createEffect, ofType } from "@ngrx/effects";
import { InstructionActions } from "../actions";
import { catchError, map, mergeMap } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { InstructionService } from "src/app/services/instruction.service";
import { of } from "rxjs";

@Injectable()
export class InstructionEffects {
  loadMyInstructions$ = createEffect(() =>
    this.actions.pipe(
      ofType(InstructionActions.loadMyInstructions),
      mergeMap((_) =>
        this.instructionService
          .loadMyInstructions()
          .pipe(
            map((instructions) =>
              InstructionActions.loadedMyInstructions({ instructions }),
            ),
          ),
      ),
    ),
  );

  loadMyConfirmedInstructions$ = createEffect(() =>
    this.actions.pipe(
      ofType(InstructionActions.loadMyConfirmedInstructions),
      mergeMap((_) =>
        this.instructionService
          .loadMyConfirmedInstructions()
          .pipe(
            map((confirmedInstructions) =>
              InstructionActions.loadedMyConfirmedInstructions({ confirmedInstructions }),
            ),
          ),
      ),
    ),
  );

  loadMyInstruction$ = createEffect(() =>
    this.actions.pipe(
      ofType(InstructionActions.loadMyInstruction),
      mergeMap((action) =>
        this.instructionService.loadMyInstruction(action.name).pipe(
          map((instruction) =>
            InstructionActions.loadedMyInstruction({
              instruction,
            }),
          ),
        ),
      ),
    ),
  );

  triggerLoadInstructionConfirmationWhenMyInstructionIsLoaded$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(InstructionActions.loadedMyInstruction),
        map((action) =>
          InstructionActions.loadMyInstructionConfirmation({
            instruction: action.instruction,
          }),
        ),
      ),
  );

  loadMyInstructionConfirmation$ = createEffect(() =>
    this.actions.pipe(
      ofType(InstructionActions.loadMyInstructionConfirmation),
      mergeMap((action) =>
        this.instructionService
          .loadMyInstructionConfirmation(action.instruction.name)
          .pipe(
            map((confirmation) =>
              InstructionActions.loadedMyInstructionConfirmation({
                confirmation,
              }),
            ),
          ),
      ),
    ),
  );

  confirmMyInstruction$ = createEffect(() =>
    this.actions.pipe(
      ofType(InstructionActions.confirmMyInstruction),
      mergeMap((action) =>
        this.instructionService
          .confirmMyInstruction(action.instruction.name)
          .pipe(
            map((confirmation) =>
              InstructionActions.confirmedMyInstruction({ confirmation }),
            ),
            catchError((error) =>
              of(InstructionActions.confirmMyInstructionFailed({ error })),
            ),
          ),
      ),
    ),
  );

  constructor(
    private readonly actions: Actions,
    private readonly instructionService: InstructionService,
  ) {}
}
